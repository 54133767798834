<template>
  <b-tabs
    content-class="col-12 col-md-12 mt-12 mt-md-0"
    pills
    nav-wrapper-class="col-md-12 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('General') }}
          </span>
      </template>

      <preset-farmers-hgs-save v-if="isLoad" />
    </b-tab>
    <!--/ general tab -->

    <!-- general tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('Detail') }}
          </span>
      </template>

      <preset-farmers-hgs-desc v-if="isLoad" />
    </b-tab>
    <!--/ general tab -->

  </b-tabs>
</template>

<script>
// Alert
import AlertService from '@/common/alert.service'

import PresetFarmersHgsSave from './PresetFarmersHgsSave.vue'
import PresetFarmersHgsDesc from './PresetFarmersHgsDesc.vue'

export default {
  components: {
    PresetFarmersHgsSave,
    PresetFarmersHgsDesc,
  },
  data() {
    return {
      isLoad: false,
    }
  },
  methods: {
    fetchItem() {
      const paramId = this.$router.currentRoute.params.id;
      if (parseInt(paramId, 10) !== 0) {
        this.$store.dispatch('presetFarmersHgsManagement/fetchPresetFarmersHg', { id: paramId })
          .then(response => {
            if (response.statusCode === 200) {
              this.isLoad = true;
              this.$store.commit('presetFarmersHgsManagement/setFarmersHgField', response.result);
            } else {
              AlertService.error(this, response.message)
            }
          })
      } else {
        this.isLoad = true;
      }
    },
  },
  created() {
    this.$store.commit('presetFarmersHgsManagement/resetFarmersHgField');
    this.fetchItem();
  },
}
</script>
