import { render, staticRenderFns } from "./PresetFarmersHgsSave.vue?vue&type=template&id=6af8a968&scoped=true&"
import script from "./PresetFarmersHgsSave.vue?vue&type=script&lang=js&"
export * from "./PresetFarmersHgsSave.vue?vue&type=script&lang=js&"
import style0 from "./PresetFarmersHgsSave.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./PresetFarmersHgsSave.vue?vue&type=style&index=1&id=6af8a968&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6af8a968",
  null
  
)

export default component.exports