<template>
  <b-card>
      <!-- form -->
      <b-form class="mt-2">

        <b-row class="mt-2">
          <b-col cols="12" md="6" lg="6">
            <label class="mb-1">{{ $t("Description") }}</label>
            <editor
              v-model="description"
              api-key="'qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc'"
              :init="editorInit"
            >
            </editor>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <label class="mb-1">{{ $t("Supplementary Info") }}</label>
            <editor
              v-model="supplementaryInfo"
              api-key="'qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc'"
              :init="editorInit"
            >
            </editor>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="12" md="6" lg="6">
            <label class="mb-1">{{ $t("Reference Samples") }}</label>
            <editor
              v-model="referenceSamples"
              api-key="'qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc'"
              :init="editorInit"
            >
            </editor>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <label class="mb-1">{{ $t("Research Links") }}</label>
            <editor
              v-model="researchLinks"
              api-key="'qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc'"
              :init="editorInit"
            >
            </editor>
          </b-col>
        </b-row>
        
        <b-row>
          <b-col cols="12">
            <save-button 
              :onClickSaveAndClose="saveItem.bind(this, true)"
              :onClickSave="saveItem.bind(this, false)"
              />
          </b-col>
        </b-row>

      </b-form>
  </b-card>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import Editor from '@tinymce/tinymce-vue'
import Ripple from 'vue-ripple-directive'
import SaveButton from '@/components/SaveButton.vue';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
// Alert
import AlertService from '@/common/alert.service'

const { mapFields } = createHelpers({
  getterType: 'presetFarmersHgsManagement/getFarmersHgField',
  mutationType: 'presetFarmersHgsManagement/updateFarmersHgField',
});

export default {
  components: {
    Editor,
    SaveButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      /* eslint-disable */
      editorInit: {
        plugins: [
          'print preview paste importcss searchreplace autolink autosave save directionality code fullscreen image link media template table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap uploadimage textcolor colorpicker',
        ],
        toolbar: 'undo redo | bold italic underline strikethrough | \
          fontselect fontsizeselect formatselect | \
          alignleft aligncenter alignright alignjustify | outdent indent | \
          numlist bullist checklist | \
          forecolor backcolor casechange permanentpen formatpainter removeformat colorpicker | pagebreak | charmap | \
          fullscreen  preview  print | insertfile image media pageembed link anchor | \
          a11ycheck ltr rtl | showcomments addcomment | uploadimage',
        language: 'tr_TR',
        min_height: 400,
        valid_elements: '*[*]',
        extended_valid_elements: 'style,link[href|rel]',
        custom_elements: 'style,link,~link',
        force_br_newlines: true,
        forced_root_block: false,
      }
    }
  },
  methods: {
    saveItem(isClose) {
      const query = this.$store.getters['presetFarmersHgsManagement/getFarmersHgForm'];
      this.$store.dispatch('presetFarmersHgsManagement/savePresetFarmersHg', query)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            })

            if (isClose) {
              this.$router.go(-1)
            } else {
              this.$store.commit('presetFarmersHgsManagement/setFarmersHgField', response.result);
              this.$router.push({ name: 'manage-preset-farmsers-hgs-save', params: { id: response.result.id } });
            }
          } else {
            AlertService.error(this, response.message)
          }
        });
    },
  },
  computed: {
    ...mapFields([
      'description',
      'supplementaryInfo',
      'referenceSamples',
      'researchLinks',
    ]),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.source-input {
    box-sizing: border-box;
    margin: 0px;
    outline: 0px;
    padding: 1em;
    height: calc(100% - 3em);
    min-height: 3em;
    width: 100%;
    overflow: auto;
    color: inherit;
    font-size: 0.7em;
    white-space: pre;
}
</style>
